import AuthView from "../views/auth/AuthView";
import Home from "../views/Home";
import Stopadvies from "../views/Stopadvies";
let routes = [
    {
        path: "/auth",
        component: AuthView,
        layout: "auth",
    },
    // {
    //     path: "/",
    //     component: Home,
    //     layout: "main",
    // },
    {
        path: "/stopadvies",
        component: Stopadvies,
        layout: "main",
    },
];
export default routes;
